import { Tab, Transition } from '@headlessui/react'
import React, { useState } from 'react'
import { classNames } from '../services/string'
import Container from './Container'

const companyHistoryContent = [
  {
    title: 'Os fundadores',
    content:
      'Nossa história teve início em dezembro de 2019, quando Ednei, fundador da Hubs, identificou uma oportunidade de auxiliar pequenos empreendedores em todo o Brasil por meio de uma solução digital de contabilidade, trazendo facilidade e economia através de um ecossistema tecnológico completo. Com um time multidisciplinar incrível, unimos experiência em negócios e tecnologia para simplificar a vida de nossos clientes. Nosso propósito é contribuir para o desenvolvimento dos pequenos negócios em todo o Brasil, reconhecendo a jornada desafiadora e heroica dos empreendedores brasileiros.',
  },
  {
    title: 'A plataforma',
    content:
      'Nossa plataforma digital é a resposta para simplificar e otimizar a gestão de empresas e facilitar a vida do empreendedor brasileiro. Imagine uma contabilidade sem papelada, burocracia e dores de cabeça. Na Hubs, descomplicamos todo o processo clássico de contabilidade em nossa plataforma simples e intuitiva, abrangendo desde a abertura de um CNPJ até o gerenciamento de obrigações fiscais, contábeis e trabalhistas.',
  },
  {
    title: 'O processo',
    content:
      'Nosso processo vai além do convencional. Com um time multidisciplinar composto pelos sócios fundadores e por colaboradores incríveis, unimos experiência em negócios e tecnologia para simplificar a vida de nossos clientes. Nosso propósito é contribuir para o desenvolvimento dos pequenos negócios em todo o Brasil através do atendimento personalizado e da tecnologia aplicada em todas as etapas, diminuindo riscos e otimizando processos. A satisfação do cliente e a transparência são os pilares que sustentam nossa operação.',
  },
]

interface Props {
  linkVideoAbout?: string | null
}

const AboutCompanyHistory = ({ linkVideoAbout }: Props) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

  return (
    <Container>
      <section className='w-full flex flex-col gap-8 py-12 xl:py-20'>
        <h3 className='font-semibold text-lg md:text-xl lg:text-3xl'>Nossa história</h3>

        <div className='flex flex-col lg:flex-row gap-8 lg:gap-12'>
          <div className='w-full lg:w-1/2'>
            <div>
              <Tab.Group onChange={(index: number) => setSelectedTabIndex(index)}>
                <Tab.List className='flex justify-between sm:justify-normal space-x-1 bg-transparent'>
                  {companyHistoryContent.map(({ title }, index) => (
                    <Tab
                      key={index}
                      value={0}
                      className={({ selected }) =>
                        classNames(
                          'py-2 md:py-2.5 text-xs md:text-sm w-32',
                          'ring-offset-0 focus:outline-none focus:ring-0',
                          selected ? 'border-b border-black font-bold' : '',
                        )
                      }
                    >
                      {title}
                    </Tab>
                  ))}
                </Tab.List>
              </Tab.Group>
            </div>
            <div className='mt-4' key={selectedTabIndex}>
              <Transition
                appear={true}
                show={true}
                enter='transition-opacity easy-linear duration-[300ms]'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                className='flex flex-col gap-8 sm:gap-12 lg:gap-16'
              >
                {companyHistoryContent[selectedTabIndex].content}
              </Transition>
            </div>
          </div>

          <div className='w-full lg:w-1/2'>
            <iframe
              loading='lazy'
              className='w-full aspect-video rounded-xl drop-shadow-lg'
              src={linkVideoAbout || ''}
              title='Apresentação da Hubs Contabilidade'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            />
          </div>
        </div>
      </section>
    </Container>
  )
}

export default AboutCompanyHistory
