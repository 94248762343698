import React, { FormEventHandler, useState } from 'react'

import Alert, { AlertVariant } from '../Alert'
import BaseMaskTextField from '../BaseMaskTextField'
import Button from '../Button'
import Container from '../Container'
import TextField from '../TextField'

import { maskPhoneNumber } from '../../services/mask'
import { JobApplicationPayload } from './types'

/** Base Strapi API URL */
const STRAPI_API_URL = process.env.STRAPI_API_URL || ''

interface FormElements extends HTMLFormControlsCollection {
  name: HTMLInputElement
  email: HTMLInputElement
  phone: HTMLInputElement
  linkedinLink: HTMLInputElement
  description: HTMLInputElement
  curriculum: HTMLInputElement
}

interface ApplicationFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

interface AlertContent {
  title: string
  variant: AlertVariant
}

interface Props {
  jobOpeningId?: number
  onSuccessCallback: () => unknown
}

const ApplicationForm = ({ jobOpeningId, onSuccessCallback }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [alertContent, setAlertContent] = useState<AlertContent | undefined>(undefined)

  const onSubmit: FormEventHandler = async (e: React.FormEvent<ApplicationFormElement>) => {
    e.preventDefault()

    if (!jobOpeningId) {
      setAlertContent({
        title: 'Não foi possível completar sua solicitação no momento. Tente novamente mais tarde.',
        variant: 'error',
      })
      return
    }

    setIsLoading(true)
    const formElements = e.currentTarget.elements

    const data: JobApplicationPayload = {
      jobId: jobOpeningId || 0,
      applicantData: {
        name: formElements.name.value,
        email: formElements.email.value,
        phone: formElements.phone.value,
        linkedinLink: formElements.linkedinLink.value,
        description: formElements.description.value,
      },
    }

    const formData = new FormData()
    formData.append('data', JSON.stringify(data))
    if (formElements.curriculum.files?.[0])
      formData.append('files.curriculum', formElements.curriculum.files[0], formElements.curriculum.files[0].name)

    try {
      const response = await fetch(`${STRAPI_API_URL}/api/job-applicants/apply`, {
        method: 'POST',
        body: formData,
      })

      setIsLoading(false)

      if (!response.ok) {
        setAlertContent({
          title: 'Houve um problema desconhecido. Confira os dados do formulário e tente novamente.',
          variant: 'error',
        })
        return
      }

      onSuccessCallback()
    } catch (e) {
      setIsLoading(false)
      setAlertContent({
        title: 'Sistema indisponível no momento. Tente novamente mais tarde.',
        variant: 'error',
      })
    }
  }

  return (
    <Container className='!mx-0 w-full bg-secondary-100 rounded-3xl p-6'>
      <div className='flex flex-col gap-8'>
        <form id='jobApplicantForm' className='flex flex-col gap-3' onSubmit={onSubmit}>
          <TextField variant='filled' id='name' label='Nome *' required disabled={isLoading} />
          <TextField variant='filled' id='email' label='Email *' type='email' required disabled={isLoading} />
          <TextField variant='filled' id='linkedinLink' label='Link do LinkedIn' type='text' disabled={isLoading} />
          <BaseMaskTextField
            NumberFormatBaseProps={{
              format: maskPhoneNumber,
            }}
            TextFieldProps={{
              id: 'phone',
              variant: 'filled',
              label: 'Telefone',
              required: false,
              disabled: isLoading,
            }}
          />
          <TextField variant='filled' id='description' label='Detalhes adicionais' type='text' disabled={isLoading} />

          <div className='flex flex-col gap-1'>
            <label className='text-sm' htmlFor='curriculum'>
              Currículo em PDF
            </label>

            <input
              className='active:outline-none focus:outline-none'
              id='curriculum'
              type='file'
              accept='application/pdf'
              disabled={isLoading}
              multiple={false}
            />
          </div>

          <Button
            type='submit'
            loading={isLoading}
            colorSchema='styled'
            className='font-light mt-2 bg-black text-white w-fit self-end'
            disabled={isLoading}
          >
            Enviar
          </Button>
        </form>
      </div>

      {alertContent && (
        <Alert open={true} title={alertContent.title} variant={alertContent.variant} onClose={() => setAlertContent(undefined)} />
      )}
    </Container>
  )
}

export default ApplicationForm
