import React, { FormEventHandler } from 'react'
import type { Property } from 'csstype'

import { maskCnpjNumber, maskPhoneNumber } from '../../services/mask'
import { ContactFormVariables } from './types'

import BaseMaskTextField from '../BaseMaskTextField'
import Button from '../Button'
import TextField, { TextFieldVariant } from '../TextField'
import { classNames } from '../../services/string'
import { findContrastColor } from '../../services/color'
import { useAnalytics } from '../../hooks'

export interface FormFieldsOptions {
  contrastReferenceColor?: Property.Color
  buttonFullWidth?: boolean
  buttonLabel?: string
  buttonColor?: Property.Color
  buttonClassName?: string
  textFieldVariant?: TextFieldVariant
  hideTextFieldLabels?: boolean
}

export interface FormFieldsProps {
  className?: string
  onSubmit: (data: ContactFormVariables) => Promise<unknown>
  loading?: boolean
  options?: FormFieldsOptions
  cnpjRequired?: boolean
  marketingCampaignCode?: string
}

const defaultOptions: FormFieldsOptions = {
  contrastReferenceColor: '#FFFFFF',
  buttonFullWidth: false,
  buttonLabel: 'Quero receber o contato do especialista',
  buttonColor: 'var(--color-primary-600)',
  buttonClassName: '',
  textFieldVariant: 'filled',
  hideTextFieldLabels: false,
}

const FormFields = ({ onSubmit: onExternalSubmit, cnpjRequired, loading, options, marketingCampaignCode }: FormFieldsProps) => {
  const { fireEvent } = useAnalytics()
  const { contrastReferenceColor, buttonFullWidth, buttonLabel, buttonColor, buttonClassName, textFieldVariant, hideTextFieldLabels } = {
    ...defaultOptions,
    ...options,
  }
  const contrastColor = findContrastColor(buttonColor || 'var(--color-primary-600)')

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault()

    const target = e.target as typeof e.target & {
      name: { value: string }
      email: { value: string }
      phone: { value: string }
      cnpj: { value: string }
    }

    // Disparo do evento de submissão do formulário de interesse
    fireEvent('generate_lead', {
      component: 'ContactForm',
      ...(marketingCampaignCode ? { marketingCampaignCode } : {}),
    })

    fireEvent('leadFormSubmitted', {
      ...(marketingCampaignCode ? { marketingCampaignCode } : {}),
    })

    onExternalSubmit({
      email: target.email.value,
      name: target.name.value,
      phone: target.phone.value,
      cnpj: target.cnpj && target.cnpj.value && target.cnpj.value.length ? target.cnpj.value : undefined,
    })
  }

  return (
    <form id='contactForm' className='flex flex-col gap-4' onSubmit={onSubmit}>
      <TextField
        id='name'
        label={(!hideTextFieldLabels && 'Nome *') || ''}
        placeholder={`Nome${(hideTextFieldLabels && ' *') || ''}`}
        contrastReferenceColor={contrastReferenceColor}
        variant={textFieldVariant}
        required
      />
      <TextField
        id='email'
        label={(!hideTextFieldLabels && 'E-mail *') || ''}
        placeholder={`E-mail${(hideTextFieldLabels && ' *') || ''}`}
        type='email'
        contrastReferenceColor={contrastReferenceColor}
        variant={textFieldVariant}
        required
      />
      <BaseMaskTextField
        NumberFormatBaseProps={{
          format: maskPhoneNumber,
        }}
        TextFieldProps={{
          id: 'phone',
          label: (!hideTextFieldLabels && 'Telefone *') || '',
          placeholder: `Telefone${(hideTextFieldLabels && ' *') || ''}`,
          contrastReferenceColor: contrastReferenceColor,
          variant: textFieldVariant,
          required: true,
        }}
      />
      {cnpjRequired && (
        <BaseMaskTextField
          NumberFormatBaseProps={{
            format: maskCnpjNumber,
          }}
          TextFieldProps={{
            id: 'cnpj',
            label: (!hideTextFieldLabels && 'CNPJ *') || '',
            placeholder: `CNPJ${(hideTextFieldLabels && ' *') || ''}`,
            contrastReferenceColor: contrastReferenceColor,
            variant: textFieldVariant,
            required: true,
          }}
        />
      )}

      <Button
        type='submit'
        colorSchema='styled'
        className={classNames(buttonFullWidth ? 'w-full' : 'lg:w-fit', 'font-light mt-4', buttonClassName)}
        style={{
          color: contrastColor,
          backgroundColor: buttonColor,
        }}
        loading={loading}
      >
        {buttonLabel}
      </Button>
    </form>
  )
}

export default FormFields
