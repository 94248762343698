import React, { Fragment, useEffect } from 'react'
import { MdCheckCircleOutline, MdClose, MdErrorOutline, MdOutlineWarningAmber, MdInfoOutline } from 'react-icons/md'
import { Transition } from '@headlessui/react'
import { classNames } from '../services/string'

export type AlertVariant = 'error' | 'warning' | 'success' | 'info'

interface AlertProps {
  open: boolean
  onClose: () => unknown
  title: string
  variant: AlertVariant
  duration?: number
}

const Alert = ({ open, title, variant, onClose, duration = 5000 }: AlertProps) => {
  const variantStyles = {
    error: 'text-red-800 bg-red-200',
    warning: 'text-orange-800 bg-orange-200',
    success: 'text-lime-800 bg-lime-200',
    info: 'text-blue-800 bg-blue-200',
  }[variant]

  const commonIconProps = {
    size: 24,
  }

  const icon = {
    error: <MdErrorOutline {...commonIconProps} />,
    warning: <MdOutlineWarningAmber {...commonIconProps} />,
    success: <MdCheckCircleOutline {...commonIconProps} />,
    info: <MdInfoOutline {...commonIconProps} />,
  }[variant]

  useEffect(() => {
    const timer = setTimeout(() => onClose(), duration)
    return () => clearTimeout(timer)
  }, [open, onClose])

  return (
    <Transition
      show={open}
      as={Fragment}
      enter='transition-opacity duration-150'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-150'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='fixed p-4 bottom-0 left-0 right-0 mx-auto md:mx-0 md:p-0 md:w-fit md:bottom-4 md:left-auto md:right-4 z-50'>
        <div className={classNames(variantStyles, 'rounded-xl py-2 px-3 drop-shadow-xl flex flex-row items-center gap-2')}>
          <div onClick={onClose}>{icon}</div>
          <p className='text-sm'>{title}</p>
          <div className='cursor-pointer p-2 rounded-full' onClick={onClose}>
            <MdClose size='24' />
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default Alert
