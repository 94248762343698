import React, { Fragment } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import { MdClose } from 'react-icons/md'

import ApplicationForm from './ApplicationForm'
import RainbowChip from '../RainbowChip'

interface Props {
  isOpen: boolean
  jobOpeningId?: number
  onClose: () => unknown
  onSuccessCallback: () => unknown
  isApplicationOverdue?: boolean
}

const JobOpeningApplicationDialog = ({ isOpen, jobOpeningId, onClose, onSuccessCallback, isApplicationOverdue }: Props) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog as='div' className='relative z-[100000]' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/40 backdrop-blur-sm' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <HeadlessDialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white border-white border-t border-l p-6 text-left align-middle transition-all shadow-lg drop-shadow-lg'>
                <div className='flex justify-between w-full mb-4 items-center'>
                  <RainbowChip label='Trabalhe na Hubs' />
                  <button
                    className='flex items-center justify-center p-1.5 bg-secondary-800 w-fit text-primary-600 rounded-full cursor-pointer'
                    onClick={() => onClose()}
                    aria-label='Fechar'
                  >
                    <MdClose size='1.2rem' />
                  </button>
                </div>

                <div className='flex flex-col gap-4 w-full'>
                  <p className='font-semibold text-lg md:text-2xl'>Faça parte do nosso time!</p>
                  <p className='font-light text-md'>
                    Faça parte de um time de especialistas em contabilidade digital e ajude a transformar a contabilidade no Brasil. Aqui,
                    prezamos pela transparência, eficiência e qualidade em todos os departamentos.
                  </p>

                  {isApplicationOverdue && (
                    <div className='w-full'>
                      <div className='text-orange-800 bg-orange-200 rounded-xl py-3 px-4 flex flex-row items-center gap-2'>
                        <div className='flex flex-col gap-1'>
                          <p className='font-semibold text-sm'>O período de inscrição dessa vaga já terminou</p>
                          <p className='text-sm'>
                            Você ainda pode se inscrever para o nosso banco de talentos. Entraremos em contato com você primeiro assim que
                            novas oportunidades surgirem.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  <p className='font-light text-md'>Aplique agora mesmo preenchendo o formulário abaixo.</p>

                  <ApplicationForm
                    jobOpeningId={jobOpeningId}
                    onSuccessCallback={() => {
                      onClose()
                      onSuccessCallback()
                    }}
                  />
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}

export default JobOpeningApplicationDialog
