import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Container from './Container'
import RainbowChip from './RainbowChip'

const AboutHero = () => (
  <Container>
    <section className='w-full flex flex-col md:flex-row gap-8 items-center justify-between py-12 xl:py-20 relative'>
      <div className='flex flex-col gap-2 md:gap-4 w-full md:w-1/2'>
        <RainbowChip label='Quem somos' />
        <h1 className='font-semibold text-lg md:text-xl lg:text-3xl'>Somos a Hubs Contabilidade</h1>
        <h2 className='font-light text-md lg:text-lg'>
          Uma plataforma de Contabilidade Online completa e inovadora, simples, eficiente, especializada em pequenas e médias empresas.
        </h2>
      </div>
      <div className='flex flex-col items-end gap-4 md:gap-8 w-full md:w-1/2'>
        <StaticImage
          src='../images/resources/about-hubs-1.png'
          alt='Primeira imagem do ambiente Hubs'
          className='max-w-[450px] rounded-xl drop-shadow-lg'
          width={450}
          placeholder='blurred'
        />
        <StaticImage
          src='../images/resources/about-hubs-2.png'
          alt='Segunda imagem do ambiente Hubs'
          className='max-w-[450px] rounded-xl drop-shadow-lg'
          width={450}
          placeholder='blurred'
        />
      </div>
      <StaticImage
        src='../images/icons/medal.svg'
        alt='Ícone de Medalha'
        className='absolute left-0 top-24 md:left-40'
        height={440}
        placeholder='blurred'
      />
    </section>
  </Container>
)

export default AboutHero
