import React from 'react'
import { Container } from '../'

export interface ArticleHeroProps {
  title: string
  subtitle?: string
}

export const ArticleHero = ({ title, subtitle }: ArticleHeroProps) => {
  return (
    <section className='w-full rounded-b-[40px] py-16 bg-gradient-to-r from-gradient-fuchsia to-gradient-blue'>
      <Container>
        <div className='flex flex-col items-center gap-2'>
          <h1 className='text-white text-center font-semibold text-2xl lg:text-3xl max-w-xl'>{title}</h1>
          {subtitle && <h2 className='text-white text-light text-center text-lg font-light lg:text-xl'>{subtitle}</h2>}
        </div>
      </Container>
    </section>
  )
}

export default ArticleHero
