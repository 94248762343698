import React from 'react'

export interface BlockRichTextProps {
  data: any
}

export const BlockRichText = ({ data }: BlockRichTextProps) => {
  return (
    <section
      className='flex flex-col text-black font-normal'
      dangerouslySetInnerHTML={{
        __html: data.body.data.childMarkdownRemark.html,
      }}
    />
  )
}

export default BlockRichText
