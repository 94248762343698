exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clubehubs-tsx": () => import("./../../../src/pages/clubehubs.tsx" /* webpackChunkName: "component---src-pages-clubehubs-tsx" */),
  "component---src-pages-como-funciona-tsx": () => import("./../../../src/pages/como-funciona.tsx" /* webpackChunkName: "component---src-pages-como-funciona-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-domesticos-tsx": () => import("./../../../src/pages/domesticos.tsx" /* webpackChunkName: "component---src-pages-domesticos-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mei-tsx": () => import("./../../../src/pages/mei.tsx" /* webpackChunkName: "component---src-pages-mei-tsx" */),
  "component---src-pages-oferta-especial-tsx": () => import("./../../../src/pages/oferta-especial.tsx" /* webpackChunkName: "component---src-pages-oferta-especial-tsx" */),
  "component---src-pages-oferta-fb-tsx": () => import("./../../../src/pages/oferta-fb.tsx" /* webpackChunkName: "component---src-pages-oferta-fb-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-parceiros-tsx": () => import("./../../../src/pages/parceiros.tsx" /* webpackChunkName: "component---src-pages-parceiros-tsx" */),
  "component---src-pages-politica-cookies-tsx": () => import("./../../../src/pages/politica-cookies.tsx" /* webpackChunkName: "component---src-pages-politica-cookies-tsx" */),
  "component---src-pages-privacidade-tsx": () => import("./../../../src/pages/privacidade.tsx" /* webpackChunkName: "component---src-pages-privacidade-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-pages-termos-tsx": () => import("./../../../src/pages/termos.tsx" /* webpackChunkName: "component---src-pages-termos-tsx" */),
  "component---src-pages-vagas-tsx": () => import("./../../../src/pages/vagas.tsx" /* webpackChunkName: "component---src-pages-vagas-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/BlogArticle.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-blog-articles-list-tsx": () => import("./../../../src/templates/BlogArticlesList.tsx" /* webpackChunkName: "component---src-templates-blog-articles-list-tsx" */),
  "component---src-templates-blog-category-articles-list-tsx": () => import("./../../../src/templates/BlogCategoryArticlesList.tsx" /* webpackChunkName: "component---src-templates-blog-category-articles-list-tsx" */),
  "component---src-templates-job-opening-tsx": () => import("./../../../src/templates/JobOpening.tsx" /* webpackChunkName: "component---src-templates-job-opening-tsx" */),
  "component---src-templates-partnership-landing-page-tsx": () => import("./../../../src/templates/PartnershipLandingPage.tsx" /* webpackChunkName: "component---src-templates-partnership-landing-page-tsx" */),
  "component---src-templates-product-landing-page-tsx": () => import("./../../../src/templates/ProductLandingPage.tsx" /* webpackChunkName: "component---src-templates-product-landing-page-tsx" */),
  "component---src-templates-segment-landing-page-tsx": () => import("./../../../src/templates/SegmentLandingPage.tsx" /* webpackChunkName: "component---src-templates-segment-landing-page-tsx" */)
}

