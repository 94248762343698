import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

interface AppStoreBadgesProps {
  className?: string
}

const AppStoreBadges = ({ className }: AppStoreBadgesProps) => {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...AppStoreBadgesInfo
      }
    }
  `)

  const appStoreBadgesInfo = strapiGlobal as Queries.AppStoreBadgesInfoFragment

  return (
    <div className={className}>
      <a href={appStoreBadgesInfo.linkAppPlayStore || '#'} rel='noreferrer noopener' target='_BLANK' className='w-32'>
        <StaticImage
          width={128}
          src='../images/resources/google-play-badge.png'
          alt='Disponível no Google Play'
          placeholder='blurred'
          quality={100}
          objectFit='contain'
        />
      </a>
      <a href={appStoreBadgesInfo.linkAppStore || '#'} rel='noreferrer noopener' target='_BLANK' className='w-32'>
        <StaticImage
          width={128}
          src='../images/resources/app-store-badge.png'
          alt='Baixar na App Store'
          placeholder='blurred'
          quality={100}
          objectFit='contain'
        />
      </a>
    </div>
  )
}

export const query = graphql`
  fragment AppStoreBadgesInfo on STRAPI_GLOBAL {
    id
    linkAppStore
    linkAppPlayStore
  }
`

export default AppStoreBadges
